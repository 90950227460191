<template>
  <div class="card p-shadow-6">
    <h1>Emails</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <Sidebar
      :visible.sync="emailDetail.show"
      position="right"
      class="p-sidebar-lg"
      v-if="emailDetail.info"
    >
      <h5>{{ emailDetail.info.subject }}</h5>
      <div>
        <b>De:</b> {{ emailDetail.info.email_from }} |
        {{ emailDetail.info.received_on }}
      </div>
      <div class="p-mt-1"><b>Para:</b> {{ emailDetail.info.email_to }}</div>
      <div class="p-mt-1" v-if="emailDetail.info.email_cc != ''">
        <b>CC:</b> {{ emailDetail.info.email_cc }}
      </div>
      <divider />
      <div>
        <Button
          icon="pi pi-trash"
          v-if="emailDetail.info.status != 4"
          class="p-button-rounded p-button-text p-button-danger"
          v-tooltip="'Eliminar Email'"
          @click="removeMessage()"
        />
      </div>
      <div
        v-if="
          emailDetail.info.message_attachments &&
          emailDetail.info.message_attachments.length > 0
        "
      >
        <divider />
        <i class="pi pi-paperclip"></i><b>Anexos:</b>
        <div>
          <Button
            class="p-button-sm p-button-rounded p-ml-1 p-mt-1"
            v-for="attachment in emailDetail.info.message_attachments"
            :key="attachment.id"
            :label="attachment.name"
            icon="pi pi-file"
            @click="downloadAttachments(emailDetail.info.id, attachment.id)"
          />
        </div>
      </div>
      <divider />
      <div>{{ emailDetail.info.body }}</div>
    </Sidebar>
    <div style="height: 65vh">
      <DataTable
        showGridlines
        :value="messages"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="streakIndex"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="message_status.name"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        @rowgroup-expand="rowExpand"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm"
              label="Mostrar todos os emails"
              @click="showAllEmails()"
            />
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.message_status.name }}
          </div>
        </template>
        <Column
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.id }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="email_from"
          header="De"
          filterField="email_from"
          :headerStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :filterHeaderStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :bodyStyle="{ 'min-width': '350px', 'max-width': '350px' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.email_from }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="email_to"
          header="Para"
          filterField="email_to"
          :headerStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :filterHeaderStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :bodyStyle="{ 'min-width': '350px', 'max-width': '350px' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.email_to }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="subject"
          header="Assunto"
          filterField="subject"
          :headerStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :filterHeaderStyle="{ 'min-width': '350px', 'max-width': '350px' }"
          :bodyStyle="{ 'min-width': '350px', 'max-width': '350px' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.subject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="received_on"
          header="Data"
          filterField="received_on"
          dataType="date"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.received_on) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column field="status" header="Estado">
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data.message_status.name)"
            ></i>
            {{ slotProps.data.message_status.name }}
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '100px', 'max-width': '100px' }"
          :filterHeaderStyle="{ 'min-width': '100px', 'max-width': '100px' }"
          :bodyStyle="{ 'min-width': '100px', 'max-width': '100px' }"
        >
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.message_ticket"
              icon="pi pi-ticket"
              class="p-button-rounded p-button-text"
              v-tooltip="'Ver Ticket'"
              @click="viewTicket(slotProps.data.message_ticket.id)"
            />
            <Button
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-success"
              v-tooltip="'Ver Email'"
              @click="readEmail(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import messageService from "../services/ticketsEmails.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";

export default {
  name: "TicketsMessages",
  components: {
    W4Mstreak,
  },
  data() {
    return {
      streakStatus: [],
      messages: [],
      expandedRowGroups: [],
      loading: false,
      filters: {
        email_to: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email_from: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        received_on: { value: null, matchMode: FilterMatchMode.DATE_IS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      emailDetail: {
        show: false,
        info: null,
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    await this.initStreakStatus();
  },
  methods: {
    async initStreakStatus() {
      this.loading = true;
      let unprocessed = await messageService.countByStatus(0);
      let unread = await messageService.countByStatus(1);
      let read = await messageService.countByStatus(2);
      let send = await messageService.countByStatus(3);
      let deleted = await messageService.countByStatus(4);
      let spam = await messageService.countByStatus(5);

      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      this.streakStatus = [
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Por Processar",
          counter: unprocessed,
          active: activeStatus === "Por Processar",
          data: [],
        },
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Não Lida",
          counter: unread,
          active: activeStatus === "Não Lida",
        },
        {
          backgroudColor: "#8E9BEF",
          textColor: "#FFF",
          title: "Lida",
          counter: read,
          active: activeStatus === "Lida",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Enviada",
          counter: send,
          active: activeStatus === "Enviada",
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Eliminada",
          counter: deleted,
          active: activeStatus === "Eliminada",
        },
        {
          backgroudColor: "#ff0000",
          textColor: "#FFF",
          title: "Spam",
          counter: spam,
          active: activeStatus === "Spam",
        },
      ];

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.message_status.name
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find((status) => status.title == info);
      return { color: statusInfo.backgroudColor };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async showAllEmails() {
      this.messages = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange({
          currentStateIndex: index,
          currentState: status.title,
        });
      }
    },
    getStreakStatusIndex(status) {
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      if (to == "") {
        return;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.messages.forEach((message, index, object) => {
        if (message.message_status.name === status) {
          object.splice(index, 1);
        }
      });
    },
    streakSelectedChange(selected) {
      if (
        this.streakStatus[selected.currentStateIndex].loaded != undefined &&
        this.streakStatus[selected.currentStateIndex].loaded == true
      ) {
        return;
      }
      this.streakStatus[selected.currentStateIndex].loaded = true;
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Por Processar":
          this.getEmailsByStatus(selected.currentStateIndex, 0);
          break;
        case "Não Lida":
          this.getEmailsByStatus(selected.currentStateIndex, 1);
          break;
        case "Lida":
          this.getEmailsByStatus(selected.currentStateIndex, 2);
          break;
        case "Enviada":
          this.getEmailsByStatus(selected.currentStateIndex, 3);
          break;
        case "Eliminada":
          this.getEmailsByStatus(selected.currentStateIndex, 4);
          break;
        case "Spam":
          this.getEmailsByStatus(selected.currentStateIndex, 5);
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getEmailsByStatus(streakIndex, status) {
      this.loading = true;
      let statusMessage = await messageService.getByStatus(status);
      statusMessage = statusMessage.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        created_on: new Date(`${v.created_on}`),
        received_on: new Date(`${v.received_on}`),
      }));
      this.messages = this.messages.concat(statusMessage);
      return (this.loading = false);
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getInfoFromEmail(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    viewTicket(ticketId) {
      let route = this.$router.resolve({
        path: `/helpdesk-tickets-detail/${ticketId}`,
      });
      window.open(route.href, "_blank");
    },
    readEmail(email) {
      messageService.getMessage(email.id).then((response) => {
        this.emailDetail.info = response;
        this.emailDetail.show = true;
        this.emailDetail.email = email;
      });
    },
    downloadAttachments(emailId, attachmentId) {
      return messageService
        .getAttachment(emailId, attachmentId)
        .then((response) => {
          let linkSource = `data:${response.mimetype};base64,${response.data}`;
          let downloadLink = document.createElement("a");
          let fileName = response.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    removeMessage() {
      this.$confirm.require({
        header: "Eliminar Email",
        message: "Tem a certeza que deseja eliminar este email?",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        accept: () => {
          return messageService
            .setStatus(this.emailDetail.email.id, { status_id: 4 })
            .then((response) => {
              this.streakStatusChange(
                this.emailDetail.email.message_status.name,
                response.status_name
              );

              this.emailDetail.email.status = response.status_id;
              this.emailDetail.email.message_status.status = response.status_id;
              this.emailDetail.email.message_status.name = response.status_name;
            });
        },
        reject: () => {},
      });
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}
</style>
